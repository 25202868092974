
  import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";
  
  import ETable from "@/components/ETable.vue";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import { userMemberRecuperateList } from "@/api/userDetection";
  import { getSubDeviceTypeList } from "@/api/deviceType";
  
  @Component({
      components: { ETable }
  })
  export default class CustomSelfExpense extends Mixins(tablePagination, loading, Resize) {
  [x: string]: any;
  
      queryForm = {
          phone: "",
          username: "",
          deviceClassId: "",
          groupName: "",
          comboName: "",
          orderNo: "",
      };
  
      itemTotal = 0;
      tableData = [];
  
      columns = [
          { label: "用户ID", prop: "id" },
          { label: "用户头像", prop: "" },
          { label: "用户昵称", prop: "" },
          { label: "用户手机号", prop: "" },
          { label: "设备分类", prop: "" },
          { label: "设备型号", prop: "" },
          { label: "设备编码", prop: "" },

          { label: "设备共享小组名称", prop: "" },
          { label: "消费时间", prop: "" },
          { label: "消费门店", prop: "" },
      ];
  

      formVisible = false;

      detailVisible = false;

      form = {};
      detailInfo = {};

      mounted() {
          this.windowResize();
          this.getData();
      }
  
      handleSearch() {
          this.resetCurrent();
          this.getData();
      }

  
      getData() {
          this.showLoading();
          const params = {
              ...this.queryForm,
              pageNum: this.currentPage,
              pageSize: this.pageSize,
          }
          userMemberRecuperateList(params).then(res => {
              this.tableData = res.data.list;
              this.itemTotal = res.data.total;
          }).finally(() => {
              this.hideLoading();
          })
      }

      add() {
        this.formVisible = true;
      }

      detail(row) {
        this.detailVisible = true;
      }
      relationDevice(row) {
        this.relationDeviceVisible = true;
      }
      managerCombo(row) {
        this.comboVisible = true;
      }

      getSubDeviceTypeList(key, parentId) {
    getSubDeviceTypeList({ parentId }).then((res) => {
      this[key] = res.data || [];
    }).catch(() => {
      this[key] = [];
    })
  }


  }
  