
import { Component, Vue } from 'vue-property-decorator'

import {
    userList
} from "@/api/serviceProvider"

import {
    debounce
} from "lodash"
@Component
export default class GetShareComboByRemote extends Vue {
    comboLoading = false;
    shareComboList = [];
    
    getShareComboRemote = debounce(function (str) {
        this.comboLoading = true;
        userList({
                phone: str,
                pageNum: 1,
                pageSize: 500
            })
            .then((res) => {
                this.comboLoading = false;
                this.shareComboList = res.data || [];
            })
            .catch((err) => {});
    })

    getShareComboList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getShareComboRemote(str);
    }
}

