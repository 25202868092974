
import {
    Component,
    Mixins,
    PropSync,
    Vue
} from "vue-property-decorator";
import ETable from "@/components/ETable.vue";

import Resize from "@/mixins/resize";
import TablePagination from "@/mixins/tablePagination";

@Component({
    components: { ETable },
})
export default class CustomSelfDetail extends Mixins(Resize, TablePagination) {
    @PropSync("detailVisible") visible: boolean;

    columns = [
        { label: "小组名称", prop: "id", align: "center" },
        { label: "套餐名称", prop: "id" },
        { label: "套餐适用设备型号", prop: "id" },
        { label: "总充值次数", prop: "id" },
        { label: "总消费次数", prop: "id" },
        { label: "总剩余次数", prop: "id" },
    ]

    tableData = []

    mounted() {
        this.setTableMaxheight();
    }

    handleClose() {
        this.visible = false;
    }
}

