
import {
    Component,
    PropSync,
    Ref, Mixins
} from "vue-property-decorator";

import GetUserByRemote from "@/mixins/getUserByRemote";
import GetShareComboByRemote from "../mixins/getShareComboByRemote";

@Component({
    components: {},
})
export default class AddCustomSelf extends Mixins(GetUserByRemote, GetShareComboByRemote) {
    @Ref("form") formRef: VForm;
    @PropSync("formVisible") visible: boolean;
    @PropSync("modelForm") formData: any;

    saveLoading = false;

    form = {
        userId: "",
        phone: "",
        nickname: "",
        groupName: "",
        comboName: "",
        remark: ""
    }

    formRules = {
        phone: [{
            required: true,
            message: "请输入用户手机号",
            trigger: "blur"
        }],
        groupName: [{
            required: true,
            message: "请选择小组",
            trigger: "blur"
        }],
        comboName: [{
            required: true,
            message: "请选择套餐",
            trigger: "blur"
        }],
    }

    handleClose() {
        this.formRef?.resetFields();
        this.visible = false;
    }

    cancel() {
        this.handleClose();
    }

    confirm() {
        this.formRef?.validate().then(() => {
            this.$emit('syncComboList');
        }).catch((err) => {
            console.log(err)
        })
    }

    setCurUser($event) {
        const user = this.allUserList.find(item => item.phone == $event);
        this.form.userId = user.id;
        this.form.nickname = user.nickname;
    }

    setShareCombo($event) {

    }
}
