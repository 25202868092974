
  import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";
  
  import ETable from "@/components/ETable.vue";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import { userMemberRecuperateList, deviceClassTypeList, getCommentsByPms } from "@/api/userDetection";

  import AddCustomSelf from "./addCustomSelf.vue";
  import CustomSelfDetail from "./customSelfDetail.vue";

  import { cloneDeep } from "lodash";
  
  @Component({
      components: { ETable, AddCustomSelf, CustomSelfDetail }
  })
  export default class CustomSelfList extends Mixins(tablePagination, loading, Resize) {
  [x: string]: any;
  
      ysbChecked = false;
  
      queryForm = {
          phone: "",
          username: "",
      };
  
      itemTotal = 0;
      tableData = [];
  
      columns = [
          { label: "用户ID", prop: "id" },
          { label: "用户头像", prop: "" },
          { label: "用户名称", prop: "" },
          { label: "用户手机号", prop: "" },
          { label: "共享小组名称", prop: "" },
          { label: "总剩余次数", prop: "" },
          { label: "总消费次数", prop: "" },
          { label: "操作", width: "200", fixed: "right", prop: "action" },
      ];
  

      formVisible = false;

      detailVisible = false;

      form = {};
      detailInfo = {};

      mounted() {
          this.windowResize(310);
          this.getData();
      }
  
      handleSearch() {
          this.resetCurrent();
          this.getData();
      }

  
      getData() {
          this.showLoading();
          const params = {
              ...this.queryForm,
              pageNum: this.currentPage,
              pageSize: this.pageSize,
          }
          userMemberRecuperateList(params).then(res => {
              this.tableData = res.data.list;
              this.itemTotal = res.data.total;
          }).finally(() => {
              this.hideLoading();
          })
      }

      add() {
        this.formVisible = true;
      }

      detail(row) {
        this.detailVisible = true;
      }
      relationDevice(row) {
        this.relationDeviceVisible = true;
      }
      managerCombo(row) {
        this.comboVisible = true;
      }


  }
  